<template>
<div>
    <wallet />
</div>
</template>

<script>
import wallet from '../components/dashboard/Wallet.vue'
export default {
    components: {
        wallet
    }
}
</script>

<style>
