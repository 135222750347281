<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar" />
      <Navbar slot="navbar" />
      <Footer slot="footer" />
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <div class="container-fluid">
          <div class="pre-loader text-center" v-if="preLoader">
            <span class="loader"></span>
          </div>

          <!-- <div class="mt-2" style="    position: sticky;
    top: 71px; background:#F5F5F5; padding-bottom:10px">  this is the recent code-->
          <div class="mt-2" style="background:#F5F5F5;">
            <div class="display-div" style="align-items:flex-end">
              <div
                style="flex-basis:40%"
                :class="{ 'horizontal-item-scroll': windowWidth < 768 }"
              >
                <div
                  style="display:flex;
    max-width:177px;
"
                  class="mb-2"
                  :class="{ 'mt-2': windowWidth < 768 }"
                >
                  <div
                    style="min-height:38px;flex-basis:50%;background:#fff ;min-width:150px; text-align:center; border-radius:30px; display:flex; align-items:center; justify-content:center; padding:0px 10px;"
                    class="mr-2 point-cursor"
                    @click="activateNairaTrans"
                  >
                    <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->

                    <!-- <img src="../../assets/images/naira-logo.png" class="mb-1 mr-2"  height="15" > -->
                    <p :class="{ 'active-market-color': !dollarWallet }">
                      Naira Transactions (<span>&#8358;</span>)
                    </p>
                  </div>

                  <div
                    style="min-height:38px; border-radius:30px;background:#fff; display:flex; min-width:160px; align-items:center; justify-content:center;  flex-basis:50%;text-align:center; padding:0px 10px"
                    class="mr-2 point-cursor hide-elem"
                    @click="activateDollarTrans"
                  >
                    <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->
                    <!-- <img src="../../assets/images/dol-logo.png"  class="mb-1 mr-2" height="15" >   -->

                    <p :class="{ 'active-market-color': dollarWallet }">
                      Dollar Transactions ($)
                    </p>
                  </div>
                </div>
                <div class="display-div" v-if="windowWidth >= 770">
                  <div class="display-div">
                    <div
                      class=" theme-btn-2 inner-div-wallet mr-2"
                      id="fund-btn"
                      data-toggle="modal"
                      data-target="#fundWallet"
                      @click="showFundModalOption"
                      style="display:flex; max-width:170px"
                      v-if="this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null"
                    >
                      <div class="text-center">
                        <img
                          src="../../assets/images/fundw.png"
                          alt=""
                          height="20"
                        />
                      </div>

                      <div class="text-center">
                        <span> {{ tran.fund_wallet }}</span>
                      </div>
                      <!-- <div class="text-left">
                                        <img src="../../assets/images/info.png"  height="20"  title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

                                    </div> -->
                    </div>
                    <template v-else>

                    <div
                      class=" theme-btn-2 inner-div-wallet mr-2"
                      id="fund-btn"
                     
                      @click="gotoBvn"
                      style="display:flex; max-width:170px"
                    
                    >
                      <div class="text-center">
                        <img
                          src="../../assets/images/fundw.png"
                          alt=""
                          height="20"
                        />
                      </div>

                      <div class="text-center">
                        <span> {{ tran.fund_wallet }}</span>
                      </div>
                      <!-- <div class="text-left">
                                        <img src="../../assets/images/info.png"  height="20"  title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

                                    </div> -->
                    </div>
                    </template>

                    <div
                      class=" theme-btn-2 inner-div-wallet mr-2"
                      id="fund-btn"
                      data-toggle="modal"
                      data-target="#TransferFundsModal"
                      :class="{ 'active-tr': activateNoty }"
                      @click="userWithdrawAmount = 0; "
                      style="display:flex; max-width:150px; align-items:center"
                   v-if="this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null"
                    >
                      <div class="text-center">
                        <img
                          src="../../assets/images/trans2.png"
                          alt=""
                          class="mr-2"
                          height="20"
                        />
                      </div>

                      <div>
                        <span> {{ tran.transfer_funds }}</span>
                      </div>
                    </div>
   <div
                      class=" theme-btn-2 inner-div-wallet mr-2"
                      id="fund-btn"
                   
                      :class="{ 'active-tr': activateNoty }"
                        @click="gotoBvn"
                      style="display:flex; max-width:150px; align-items:center"
                   v-else
                    >
                      <div class="text-center">
                        <img
                          src="../../assets/images/trans2.png"
                          alt=""
                          class="mr-2"
                          height="20"
                        />
                      </div>

                      <div>
                        <span> {{ tran.transfer_funds }}</span>
                      </div>
                    </div>
                    <div
                      class=" theme-btn-2 inner-div-wallet mr-2"
                      id="fund-btn2"
                      style="display:flex; max-width:160px"
                      @click="sendToProfilePage"
                      v-if="userProfileInfo.bvn == null"
                    >
                      <div class="text-center">
                        <img
                          src="../../assets/images/withdraw2.png"
                          alt=""
                          class="mr-2"
                          height="20"
                        />
                      </div>
                      <div>
                        <span> {{ tran.withdraw_funds }}</span>
                      </div>
                    </div>
                    <div
                      class=" theme-btn-2 inner-div-wallet mr-2"
                      id="fund-btn2"
                      data-toggle="modal"
                      data-target="#withdrawModal"
                      @click="emptyWithdrawData"
                      style="display:flex; max-width:160px"
                      v-else
                    >
                      <div class="text-center">
                        <img
                          src="../../assets/images/withdraw2.png"
                          alt=""
                          class="mr-2"
                          height="20"
                        />
                      </div>
                      <div>
                        <span> {{ tran.withdraw_funds }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="flex-basis:40%; display:flex;flex-direction:column;align-items:flex-end"
                v-if="windowWidth >= 770"
              >
                <div
                  class=" mb-2"
                  style="border: 0.5px solid #fff; border-radius:5px;background:#fff;align-items:center;  padding: 0px 10px;max-width: 595px;"
                >
                  <div
                    class="display-div"
                    style="align-items:center;min-height:38px"
                    @click="copyRefCode($event)"
                    :content="tran.ref_to_clipboard"
                    v-tippy="{
                      trigger: 'click',
                      hideOnClick: true,
                      sticky: false,
                    }"
                  >
                    <div>
                      <img src="../../assets/images/speaker.png" height="20" />
                    </div>
                    <div style="opacity:0; width:1px">
                      <input
                        class="ref-url"
                        type="hidden"
                        :value="`https://requid.me/${userProfileInfo.ref_code}`"
                      />
                    </div>
                    <div>
                      <p class="point-cursor">
                        {{ `https://requid.me/${userProfileInfo.ref_code}` }}
                      </p>
                    </div>
                    <div class="text-right">
                      <img
                        src="../../assets/images/info.png"
                        height="20"
                        title="Invite friends using this Referral link and get 1% for every first investment they make."
                        v-tippy="{
                          arrow: true,
                          placement: 'top',
                          arrowType: 'round',
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="display-div" style="justify-content:flex-end">
                  <div
                    style="max-width:200px;"
                    :class="{
                      'mr-2': windowWidth >= 1200,
                      'mb-2': windowWidth < 1200,
                    }"
                  >
                    <div
                      class="display-div"
                      style="border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center; 
                                                
                                               padding: 5px 10px"
                    >
                      <div>
                        <img src="../../assets/images/wall.png" height="20" />
                      </div>

                      <div>
                        <p
                          style="color: #828282; font-size:12px"
                          class="text-right"
                        >
                          Wallet Balance
                        </p>

                        <h5
                          class="text-right"
                          v-if="returnComponentBalance == '' && nairaWallet"
                        >
                          <span>&#8358;</span>
                          {{ formatAmount(userProfileInfo.balance) }}
                        </h5>

                        <h5
                          class="text-right"
                          v-if="returnComponentBalance !== '' && nairaWallet"
                        >
                          <span>&#8358;</span>
                          {{ formatAmount(returnComponentBalance) }}
                        </h5>

                        <h5 class="text-right" v-if="dollarWallet">
                          $ {{ formatAmount(walletData.usd_balance) }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div
                    style="max-width:200px;"
                    data-toggle="modal"
                    data-target="#referralModal"
                  >
                    <div
                      class="display-div point-cursor"
                      style="border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center; 
                                                
                                                padding: 5px 10px"
                    >
                      <div>
                        <img src="../../assets/images/bonus.png" height="20" />
                      </div>

                      <div>
                        <p
                          style="color: #828282; font-size:12px"
                          class="text-right"
                        >
                          Bonuses
                        </p>

                        <h5 class="text-right">
                          <template v-if="dollarWallet">
                            $ {{ formatAmount(0) }}
                          </template>

                          <template v-if="nairaWallet">
                            <span>&#8358;</span>
                            {{ formatAmount(accountOverviewBonus) }}
                          </template>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Transaction table -->

          <div
            style="display:flex; justify-content:space-around"
            class="pt-4"
            v-if="windowWidth > 769"
          >
            <div class="wallet-div">
              <div class="display-div">
                <div style="flex-grow:5" class="mb-2">
                  <h3 class="text-left head-font">{{ tran.tran_history }}</h3>
                  <!-- <p class="mb-4">{{tran.history_of}}</p> -->
                </div>
              </div>
              <div class="table-responsive mb-4">
                <div class="text-center" v-if="walletTransactions.length < 1">
                  <p class="text-center mt-4">
                    {{ tran.sorry_no_tranx }}
                  </p>
                  <button
                    class="theme-btn-2 mt-20"
                    style="min-height:70px; min-width:200px"
                    @click.prevent="sendToMarketPlace"
                  >
                    {{ tran.buy_products }}
                  </button>
                </div>
                <table v-if="walletTransactions.length > 0">
                  <thead class="table-head">
                    <!-- <th>{{tran.id}}</th> -->
                    <th>{{ tran.id }}</th>
                    <th>{{ tran.amount }}</th>
                    <th>{{ tran.type }}</th>
                    <th>{{ tran.date }}</th>
                    <th>{{ tran.status }}</th>
                  </thead>

                  <tbody slot="body">
                    <!-- <tr v-for="row in filterWalletData(walletTransactions)" :key="row.index"> this is the updated code -->
                    <tr v-for="row in walletTransactions" :key="row.index">
                      <td>{{ row.ref_no }}</td>
                      <td><span>₦</span>{{ formatAmount(row.amount) }}</td>
                      <td>{{ row.tranx_type }}</td>

                      <td>{{ row.created_at | formatTimeDate }}</td>
                      <td>{{ tran.success }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!--Fund wallet Modal -->
          <div
            class="modal fade trans-modal"
            id="fundWallet"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered"
              role="document"
              style="max-width: 520px;"
            >
              <!-- <div class="modal-content" v-if="fundOptionModal">
                                            <div class="modal-header">
                                                <h4 class="modal-title head-font" id="exampleModalLongTitle">{{tran.fund_wallet}}</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                                               </button>
                                            </div>
                                            <div class="modal-body text-left">
                                                <div class="card bg-light mb-3" style="max-width: 28rem;">
                                                    <div style="min-height:100px" class="fund-input">
                                                        <p>{{tran.amount_to_fund}} <span>&#8358;</span> :</p>
                                                        <input type="text" id="fundInput" :value="returnMinAmount" @keyup="getFundValue($event)" class="form-input text-center" autofocus>
                                                     </div>

                                                        <div>
                                                            <p>{{tran.due_to_monify}}</p>

                                                            <div style="background:#fafafa;padding:20px;color:#0094FF; text-align:Center" class="mt-2">
                                                                <h3> <span>&#8358;</span><b>{{returnMinAmountUserIsPaying}}</b></h3>

                                                            </div>
                                                        </div>

                                                        <div class="card-body">
                                                            <template v-if="cartItemLength > 0 && fundWalletFromWalletScreen">
                                                                <div class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                                                                    <input type="checkbox" class="custom-control-input" id="customControlInline1" v-model="sponsor" @click="checkForSponsor($event)">
                                                                    <label class="custom-control-label" :title="tran.sponsor_items_message " v-tippy='{ arrow : true, theme:"light", placement:"bottom",arrowType : "round"}' for="customControlInline1"><b>{{tran.sponsor_items}}</b></label>

                                                                </div>
                                                            </template>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="modal-footer text-center" @click="fundWalletNow" :class="{'fade-elem' : returnFundBtnStatus}">
                                                    <span>{{ loader ? '' : tran.proceed_to_pay}} </span>

                                                    <span class="loader" v-if="loader" style="top:92%"></span>
                                                </div>

                                            </div> -->

              <div
                class="modal-content"
                style="background-image: linear-gradient(180deg, #23AEE6 0%, #44CFA9 91.31%, #FFFFFF 91.35%); border-radius: 10px; "
              >
                <div class="modal-header text-right animated fadeIn" style="padding: 1rem 1rem !important">
                  <p
                    style="color:#fff; font-size:17px"
                    id="exampleModalLongTitle"
                  >
                    Pay into
                  </p>

                  <button
                    type="button"
                    style="z-index:2"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="closePayWithCardBox"
                  >
                    <span aria-hidden="true"> &times; </span>
                  </button>
                </div>
                <div class="modal-body" style="    min-height: 150px;">
                  <!-- <img src="../../assets/images/elli.png"  height="140" style="position: absolute;
    right: 0px;
    top: -10px;z-index:0"> -->

                  <!-- <img src="../../assets/images/elli2.png"  height="100" style="position: absolute;
    right: 0px;
  bottom:0px"> -->
  <!-- <div style="display:flex; background:#fff; border-radius:10px; padding:10px" class="mb-4">
    <div style="flex-basis:10%">
  <p>
    <i class="fa fa-info-circle" style="font-size:30px"></i>
  
  </p>
    </div>
    <div style="flex-basis:90%">
  <p style="font-size:16px">
  
    Do not use the Providus bank account for wallet funding for now, as we are experiencing temporary delays from the service provider. For <b> instant wallet credit</b>,  use the <span    @click="userWantsToPayWithCard" class="point-cursor" style="text-decoration:underline; color:#0094ff"> Pay with Card</span> option.
  </p>
  <p style="font-size:16px" class="pt-4">
    <b>Slower Alternative: </b> Pay into the account below and send evidence of payment to support@requid.com (verification takes a few business hours).
  </p>
    </div>
  </div> -->

                  <template>
                    <!-- <div style="display:grid;grid-template-columns:50% 50%; grid-gap:10px">

                                                            <div style="background:#F5F5F5;border-radius:10px;padding:10px;min-height:40px
">  <p class="text-left" style="font-size:20px; color:#4F4F4F;">{{userProfileInfo.virtual_bank_name}}</p>
<div style="border-top:0.5px solid #E0E0E0"></div>
<p class="text-left" style="font-size:20px; color:#4F4F4F;">{{userProfileInfo.virtual_account_num | spaceOutNumbers}}</p>
<div style="border-top:0.5px solid #E0E0E0"></div>
<p class="text-left" style="font-size:20px; color:#4F4F4F;">{{userProfileInfo.virtual_account_name}}</p>
                                                            </div>
                                                             <div style="background:#F5F5F5;border-radius:10px;padding:10px;min-height:40px
">

                                                            </div>
                                                        </div> -->

                                                        <template  v-if="userProfileInfo.virtual_account_name == ''">

                                                      
                                                        <p class="text-left" style="font-size:16px;color:#fff">
                                                          No bank details yet!
                                                         </p>
<button @click="fundWalletOffline" style="background: none;border: 1px solid transparent;border-bottom: 1px solid #fff; color:#fff" class="mt-3" v-if=" !transferFundsLoader">
  Click here to generate bank details
</button>
<p class="text-left mt-3" style="font-size:16px;color:#fff" v-if=" authLoader || transferFundsLoader">
 please wait...... getting bank details <i class="fa fa-spin fa-spinner" style="color:#fff"></i>
</p>

</template>
<template v-if="userProfileInfo.virtual_account_name !== ''">


                    <div class="mb-2">
                      <p class="text-left" style="font-size:13px;color:#E0E0E0">
                        Bank
                      </p>
                      <p class="text-left" style="font-size:20px; color:#fff">
                        <b>{{ userProfileInfo.virtual_bank_name }}</b>
                      </p>
                    </div>
                    <div class="mb-2">
                      <p class="text-left" style="font-size:13px;color:#E0E0E0">
                        Account Number
                      </p>
                      <p class="text-left" style="font-size:20px; color:#fff">
                        <b>{{
                          userProfileInfo.virtual_account_num | spaceOutNumbers
                        }}</b>
                      </p>
                    </div>

                    <div class="mb-3">
                      <p class="text-left" style="font-size:13px;color:#E0E0E0">
                        Account Name
                      </p>
                      <p class="text-left" style="font-size:20px; color:#fff">
                        <b>{{ userProfileInfo.virtual_account_name }}</b>
                      </p>
                    </div>
</template>

                    <!-- divider -->
                    <hr style="border-top: 1.5px solid #E4FAE1;"  v-if="userProfileInfo.virtual_account_name !== ''"/>


                    <template v-if="userProfileInfo.virtual_account_name !== ''">
                    <p style="color:#FFF;position:relative" class="mb-1 mt-3">
                      Fund your wallet ANYTIME with the account details above.
                    </p>

                    <p style="color:#FFF;position:relative" class="mb-3">
                      Tip: Also save it as a beneficiary.
                    </p>
                    <p style="color:#FFF;position:relative">
                      <span style="color:#F4E6B2">₦50</span> is deducted to
                      enable instant wallet updates.
                    </p>
                    </template>



                    <!-- 
                                                     <div class="text-center">

                                                            <img src="../../assets/images/monify.png"  height="40" >
                                                     </div> -->
                  </template>
                </div>
                <!-- @click="fundWalletOnline" data-dismiss="modal" aria-label="Close"  -->
                <div class="modal-footer text-center pay-with-card-link " v-if="userProfileInfo.virtual_account_name !== ''">
                  <div
                    class="tran-height"
                    :class="{ 'pay-with-card': payWithCard }"
                  >
                    <div class="display-flex mb-3">
                      <div style="display:flex; justify-content:flex-start">
                        <img
                          src="../../assets/images/paywith2.png"
                          height="30"
                        />
                      </div>

                      <div style="display:flex; justify-content:flex-end">
                        <img
                          src="../../assets/images/cardgroup.png"
                          height="25"
                        />
                      </div>
                    </div>
                    <div class="display-flex">
                      <div>
                        <p class="text-left" style="color:#4F4F4F">Amount</p>
                      </div>

                      <div style="display:flex; justify-content:flex-end">
                        <p style="color:#BDBDBD; font-size:11px">
                          each unit costs <span>&#8358;</span>5,000
                        </p>
                      </div>
                    </div>
                    <div>
                      <input
                        type="text"
                        id="cardPay"
                        class="pl-2"
                        style="min-width: 100%; border: 0.5px solid #BDBDBD; box-sizing: border-box;border-radius: 2px; min-height:40px"
                        v-model="payWithCardInput"
                        @keyup="getFundValue($event)"
                      />

                      <p
                        class="text-center pt-3 continue-active"
                        :class="{
                          'continue-inactive': returnFundBtnStatus,
                          'fade-elem':
                            returnFundBtnStatus && payWithCardInput == '',
                        }"
                        @click="fundWalletOnline"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Continue
                        <i class="fa fa-spin fa-spinner" v-if="loader"></i>
                      </p>
                    </div>
                  </div>
                  <div
                    class="text-center"
                    style="min-width:100%"
                    v-if="!payWithCard"
                  >
                    <p
                      class="text-center mr-2"
                      style="cursor:pointer; color:#0094FF;"
                    >
                      <img
                        src="../../assets/images/paywith.png"
                        height="40"
                        @click="userWantsToPayWithCard"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Mobile View -->
          <div class="mobile-view " v-if="windowWidth < 770">
            <div class="row mobile-sum-div mt-1">
              <div class="col-md-6 col-6">
                <p class="mb-1">
                  <img
                    src="../../assets/images/walltxt.png"
                    alt=""
                    height="20"
                  />
                </p>

                <p
                  style="font-size:18px; color:#58B46F"
                  class="fw-600 pl-1"
                  v-if="returnComponentBalance == '' && nairaWallet"
                >
                  <span>&#8358;</span
                  >{{ formatAmount(userProfileInfo.balance) }}
                </p>

                <p
                  style="font-size:18px; color:#58B46F"
                  class="fw-600 pl-1"
                  v-if="returnComponentBalance !== '' && nairaWallet"
                >
                  <span>&#8358;</span>
                  {{ formatAmount(returnComponentBalance) }}
                </p>
                <p
                  style="font-size:18px; color:#58B46F"
                  class="fw-600 pl-1"
                  v-if="dollarWallet"
                >
                  ${{ formatAmount(walletData.usd_balance) }}
                </p>
              </div>
              <div
                class="col-md-6 col-6"
                data-toggle="modal"
                data-target="#referralModal"
              >
                <p class="mb-1">
                  <img
                    src="../../assets/images/bonustxt.png"
                    alt=""
                    height="20"
                  />
                </p>
                <p
                  style="font-size:18px; color:#0094FF"
                  class="fw-600 pl-1"
                  v-if="nairaWallet"
                >
                  <span>&#8358;</span>{{ formatAmount(accountOverviewBonus) }}
                </p>
                <p
                  style="font-size:18px; color:#0094FF"
                  class="fw-600 pl-1"
                  v-if="dollarWallet"
                >
                  ${{ formatAmount(0) }}
                </p>
              </div>
              <!-- <div class="col-md-12 col-12 mt-2 mb-2" style="border-top:0.5px solid #E0E0E0;">

       </div> -->
              <!-- <div class="col-md-6 col-6">
                                           <p class="mb-1"><img src="../../assets/images/totalcr.png" alt="" height="20"></p>

                                                    <template v-if="accountOverviewCredits !== null && accountOverviewCredits !== undefined">
                                                        <p class="pl-1" style="color:#828282; font-size:15px"> <b>{{"₦" + accountOverviewCredits}} </b></p>

                                                    </template>

                                                    <p class="pl-1" style="color:#828282; font-size:15px" v-else><b>{{"₦"+0 +'.00'}}</b></p>
                                            </div>

                                              <div class="col-md-6 col-6">
                                       <p class="mb-1"><img src="../../assets/images/totaldb.png" alt="" height="20"></p>
                                                    <template v-if="accountOverviewDebits !== null && accountOverviewDebits !== undefined">
                                                        <p class="pl-1"  style="color:#828282; font-size:15px"><b> 
                                                            {{"₦" +accountOverviewDebits}}</b></p>
                                                    </template>
                                                    <p v-else class="pl-1" style="color:#828282; font-size:15px"><b>{{"₦"+0 +'.00'}}</b></p>
                                            </div>
                                             -->

              <div
                class="col-md-12 col-12"
                style="padding-right: 0px;
    padding-left: 0px; margin-top:10px"
              >
                <div
                  class=" mb-2"
                  style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF;align-items:center; min-width:100%; padding: 0px 10px"
                  v-if="windowWidth <= 1500"
                >
                  <div
                    class="display-div"
                    style="align-items:center;min-height:33px"
                    @click="copyRefCode($event)"
                    :content="tran.ref_to_clipboard"
                    v-tippy="{
                      trigger: 'click',
                      hideOnClick: true,
                      sticky: false,
                    }"
                  >
                    <div>
                      <img src="../../assets/images/speaker.png" height="15" />
                    </div>
                    <div style="opacity:0; width:1px">
                      <input
                        class="ref-url"
                        type="hidden"
                        :value="`https://requid.me/${userProfileInfo.ref_code}`"
                      />
                    </div>
                    <div>
                      <p class="point-cursor">
                        {{ `https://requid.me/${userProfileInfo.ref_code}` }}
                      </p>
                    </div>
                    <div class="text-right">
                      <img
                        src="../../assets/images/info.png"
                        height="20"
                        title="Invite friends using this Referral link and get 1% for every first investment they make."
                        v-tippy="{
                          arrow: true,
                          placement: 'top',
                          arrowType: 'round',
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mb-3 mt-3"
              style="justify-content:space-evenly; display:flex; "
            >
              <div
                class="point-cursor text-center mt-2"
                style="flex-basis:30%"
                data-toggle="modal"
                data-target="#fundWallet"
                @click="showFundModalOption"
                v-if="this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null"
              >
                <img
                  src="../../assets/images/tranfbtn.png"
                  alt=""
                  height="40"
                />
                <p class="pt-2" style="color: #2B8743; font-size:13px ">
                  FUND
                </p>
              </div>
                  <div
                class="point-cursor text-center mt-2"
                style="flex-basis:30%"
             
                @click="gotoBvn"
                v-else
              >
                <img
                  src="../../assets/images/tranfbtn.png"
                  alt=""
                  height="40"
                />
                <p class="pt-2" style="color: #2B8743; font-size:13px ">
                  FUND
                </p>
              </div>
              <div
                class="point-cursor text-center mt-2"
                style="flex-basis:30%"
                data-toggle="modal"
                data-target="#TransferFundsModal"
                :class="{ 'active-tr': activateNoty }"
                @click="emptyWithdrawData"
                 v-if="this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null"
              >
                <img
                  src="../../assets/images/transfund.png"
                  alt=""
                  height="40"
                />
                <p class="pt-2" style="color:#1A659C; font-size:13px ">
                  TRANSFER
                </p>
              </div>
              <div
                class="point-cursor text-center mt-2"
                style="flex-basis:30%"
              
                :class="{ 'active-tr': activateNoty }"
                @click="gotoBvn"
                v-else
              >
                <img
                  src="../../assets/images/transfund.png"
                  alt=""
                  height="40"
                />
                <p class="pt-2" style="color:#1A659C; font-size:13px ">
                  TRANSFER
                </p>
              </div>
              <div
                class="point-cursor text-center mt-2"
                style="flex-basis:30%"
                @click="sendToProfilePage"
                v-if="userProfileInfo.bvn == null"
              >
                <img
                  src="../../assets/images/withdrawbtn.png"
                  alt=""
                  height="40"
                />
                <p class="pt-2" style="color:#CD1C44; font-size:13px ">
                  WITHDRAW
                </p>
              </div>
              <div
                class="point-cursor text-center mt-2"
                style="flex-basis:30%"
                data-toggle="modal"
                data-target="#withdrawModal"
                @click="emptyWithdrawData"
                v-else
              >
                <img
                  src="../../assets/images/withdrawbtn.png"
                  alt=""
                  height="40"
                />
                <p class="pt-2" style="color:#CD1C44; font-size:13px ">
                  WITHDRAW
                </p>
              </div>
            </div>

            <!-- 
                                            <div class="display-div pt-3 pb-4">
                                                <div class="display-div mr-2 inner-div-wallet" style="background: #fff !important; min-width:45% !important; border-radius:5px;border: 1px solid #BDBDBD; cursor:pointer !important" @click="sendToProfilePage" v-if="userProfileInfo.bvn == null">
                                                    <div class="text-left pl-4">
                                                        <img src="../../assets/images/with.png" alt="" height="20">
                                                          </div>

                                                        <div class="text-left">
                                                            <p> {{tran.withdraw_funds}}</p>
                                                        </div>

                                                    </div>
                                                    <div class="display-div mr-2 inner-div-wallet" data-toggle="modal" data-target="#withdrawModal" style="background: #f1faf0 !important; min-width:45% !important; border-radius:5px;border: 1px solid #BDBDBD; cursor:pointer !important" v-else>
                                                        <div class="text-left pl-4">
                                                            <img src="../../assets/images/with.png" alt="" height="20">
                                                        </div>

                                                            <div class="text-left">
                                                                <p> {{tran.withdraw_funds}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="display-div inner-div-wallet theme-btn-2" data-toggle="modal" data-target="#fundWallet" style="min-width:50% !important; border-radius:5px; cursor:pointer !important" @click="showFundModalOption">
                                                            <div class="text-left pl-4">
                                                                <img src="../../assets/images/piggy.png" alt="" height="20">

                                                                 </div>

                                                                <p> {{tran.fund_wallet}}</p>

                                                            </div>
                                                        </div> -->

            <h4 class="text-center">
              <b>{{ tran.tran_history }}</b>
            </h4>
            <p v-if="walletTransactions.length < 1" class="text-center mt-4">
              {{ tran.sorry_no_tranx }}
            </p>
            <!-- Card for transactions -->
            <!-- <div class="card card-mobile mb-3 mt-2" v-for="(prod) in filterWalletData(walletTransactions)" :key="prod.product_id"> This is the recent code-->
            <div
              class="card card-mobile mb-3 mt-2"
              v-for="prod in walletTransactions"
              :key="prod.product_id"
            >
              <div class="row no-gutters">
                <div
                  class="col-md-2 col-2 pl-1"
                  style="max-width: 15.666667%; display:flex; align-items:center; justify-content:center"
                >
                  <img
                    src="../../assets/images/withmob.png"
                    height="40"
                    v-if="prod.tranx_type == 'Debit'"
                  />
                  <img
                    src="../../assets/images/depo.png"
                    height="40"
                    v-if="prod.tranx_type !== 'Debit'"
                  />
                </div>

                <div class="col-md-6 col-6">
                  <div class="pl-2 pt-3 pb-3">
                    <div>
                      <h6 class="card-title">{{ prod.tranx_type }}</h6>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-4 col-4 text-right animated"
                  style="display:flex; flex-direction:column; justify-content:center"
                >
                  <!--  <div class="btn-group">
                                                                            <button type="button" data-toggle="dropdown" class="no-border-no-bg" aria-haspopup="true" aria-expanded="false">
                                                                     <i class="fa fa-ellipsis-h"></i>
                                                                    </button>
                                                                          <div class="dropdown-menu dropdown-menu-right">
                                                                                <button class="dropdown-item" type="button" style="cursor:pointer"></button>
                                                                                <hr style="margin:0rem">
                                                                                <button class="dropdown-item" type="button" style="cursor:pointer !important"> </button>
                                                                            </div>
                                                                        </div>-->
                  <h5 class="card-title-amount">
                    <span>&#8358;</span>{{ formatAmount(prod.amount) }}
                  </h5>
                  <p style="opacity:0.4">
                    <span> {{ prod.created_at | formatDate }} </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- withdraw modal -->
          <div
            class="modal fade"
            id="withdrawModal"
            data-backdrop="static"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header text-right">
                  <h4 class="modal-title" id="exampleModalLongTitle"></h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="resetWallets"
                  >
                    <span aria-hidden="true"> &times; </span>
                  </button>
                </div>

                <div class="modal-body">
                  <div
                    class="text-center"
                    v-if="!userProfileInfo.has_pin && !withdrawProcessing"
                  >
                    <img
                      src="../../assets/images/pin_image.png"
                      class="mr-1 ml-1"
                      height="80"
                    />

                    <h6 style="color:#828282" class="mt-3">Setup your PIN</h6>
                    <hr />

                    <p style="color:#828282">
                      To withdraw funds, you need to...
                    </p>

                    <p
                      style="color:#0094FF"
                      data-dismiss="modal"
                      class="mt-3 point-cursor"
                      @click="userWantsTosetPin"
                    >
                      ...create a PIN
                    </p>
                  </div>
                  <template
                    v-if="userProfileInfo.has_pin && !withdrawProcessing"
                  >
                    <h5
                      class="head-font  text-center mb-4"
                      v-if="!editWithdrawal && nairaWallet"
                    >
                      {{ tran.withdraw_funds }} - Naira Wallet
                    </h5>
                    <h5
                      class="head-font  text-center mb-4"
                      v-if="dollarWallet && !nairaWallet && !editWithdrawal"
                    >
                      {{ tran.withdraw_funds }} - Dollar Wallet ($)
                    </h5>
                    <h5 class="head-font  text-left mb-3" v-if="editWithdrawal">
                      Confirm Withdrawal
                    </h5>

                    <div
                      style="background: #FFFAF0; text-align:center; padding:10px; border-radius:10px; display:flex; align-items:center; justify-content:center"
                      class="mb-3"
                      v-if="editWithdrawal"
                    >
                      <img
                        src="../../assets/images/info.png"
                        height="20"
                        class="mr-4"
                      />
                      <p>Expect funds within 1 - 2 Working days</p>
                    </div>
                    <div
                      style="min-height:50px; border-bottom:1px solid #E0E0E0;"
                      class="mb-3"
                      v-if="editWithdrawal"
                    >
                      <p style="color:#828282">Amount</p>

                      <p
                        class="text-center"
                        v-if="editWithdrawal && nairaWallet"
                      >
                        <span>&#8358;</span> {{ userWithdrawAmount }}
                      </p>
                      <p
                        class="text-center"
                        v-if="editWithdrawal && dollarWallet"
                      >
                        $ {{ dollarWithdrawalValue }}
                      </p>
                    </div>
                    <div
                      style="min-height:50px; border-bottom:1px solid #E0E0E0;"
                      class="mb-3"
                      v-if="editWithdrawal"
                    >
                      <p style="color:#828282">From</p>

                      <p
                        class="text-center"
                        style="color:#828282"
                        v-if="nairaWallet"
                      >
                        reQuid Naira Wallet
                      </p>
                      <p
                        class="text-center"
                        style="color:#828282"
                        v-if="dollarWallet"
                      >
                        reQuid Dollar Wallet
                      </p>
                    </div>
                    <div
                      style="min-height:50px; "
                      class="mb-3"
                      v-if="editWithdrawal"
                    >
                      <p style="color:#828282">To</p>

                      <div
                        style="background:#FAFAFA;border-radius: 10px;min-height:50px;  justify-content:center; color:#828282;align-items:center; padding:10px "
                      >
                        <p v-if="dollarToNairaWallet && dollarWallet">
                          reQuid Naira Wallet
                        </p>

                        <template v-if="nairaWallet || dollarToBank">
                          <p
                            class="pl-4 pb-1"
                            style="flex-basis: 33%;border-left:1px solid #828282"
                          >
                            {{ userProfileInfo.bank_name }}
                          </p>
                          <p
                            style="flex-basis: 30%; border-left:1px solid #828282"
                            class="pl-4 pb-1"
                          >
                            {{
                              userProfileInfo.bank_account_no | spaceOutNumbers
                            }}
                          </p>
                          <p
                            style="flex-basis: 30%; border-left:1px solid #828282"
                            class="pl-4"
                          >
                            {{ userProfileInfo.bank_account_name }}
                          </p>
                        </template>
                      </div>
                    </div>
                    <div class="withdraw-bal" v-if="!editWithdrawal">
                      <p style="color:#828282">{{ tran.wallet_bal }} :</p>
                      <p
                        v-if="returnComponentBalance == '' && nairaWallet"
                        class="text-center"
                        style="color:#828282"
                      >
                        <b
                          ><span>&#8358;</span
                          >{{ formatAmount(userProfileInfo.balance) }}
                        </b>
                      </p>
                      <p
                        v-if="returnComponentBalance !== '' && nairaWallet"
                        class="text-center"
                        style="color:#828282"
                      >
                        <b
                          ><span>&#8358;</span>
                          {{ formatAmount(returnComponentBalance) }}
                        </b>
                      </p>

                      <p
                        v-if="dollarWallet"
                        class="text-center"
                        style="color:#828282"
                      >
                        <b> ${{ formatAmount(walletData.usd_balance) }} </b>
                      </p>
                    </div>

                    <div
                      style="min-height:100px"
                      class="withdraw-input mt-4"
                      v-if="!editWithdrawal && nairaWallet"
                    >
                      <div v-if="nairaWallet">
                        <p class="mb-2">{{ tran.req_amount }}</p>
                        <money
                          v-model="userWithdrawAmount"
                          v-bind="money"
                        ></money>

                        <p
                          class="error-col pt-2"
                          style="    font-size: 12px;
    text-align: center;"
                          v-if="withdrawErrorMsg"
                        >
                          {{ tran.you_hv_insufficient_bal }}
                        </p>
                      </div>
                    </div>

                    <template v-if="!editWithdrawal">
                      <div
                        style="border-top:1px solid #E0E0E0; border-bottom:1px solid #E0E0E0; display:flex; justify-content:center; padding:10px; align-items:center; min-height:55px"
                        class="mb-3 mt-3"
                        v-if="dollarWallet"
                      >
                        <p style="color:#828282" class="mr-4">
                          Today’s exchange rate:
                        </p>
                        <p style="color:#828282">
                          $1.00
                          <img
                            src="../../assets/images/frame.png"
                            class="mr-1 ml-1"
                            height="10"
                          />
                          <b
                            ><span>&#8358;</span
                            >{{
                              formatAmount(walletData.usd_exchange_rate_sell)
                            }}
                          </b>
                        </p>
                      </div>

                      <div
                        style="display:flex; background:#F5F5F5;border-radius: 6px; padding:10px; justify-content:space-between"
                        v-if="dollarWallet"
                      >
                        <div style="flex-basis:48%">
                          <p class="pt-2 pb-2">Request Dollar Amount:</p>
                          <money
                            v-model="dollarWithdrawalValue"
                            v-bind="money"
                          ></money>
                        </div>
                        <div style="flex-basis:48%;">
                          <p class="pt-2 pb-2">Naira Equivalent:</p>
                          <p
                            style="color:#828282;padding:5px;    min-height: 47px; display:flex; align-items:center; justify-content:center"
                            class="text-center"
                          >
                            <b
                              ><span>&#8358;</span
                              >{{ formatAmount(returnDollarEquiv) }}
                            </b>
                          </p>
                        </div>
                      </div>

                      <div
                        style="display:flex; justify-content:space-between"
                        class="mt-3 point-cursor"
                        :class="{
                          'fade-elem':
                            dollarWithdrawalValue < 1 ||
                            dollarWithdrawalValue > walletData.usd_balance,
                        }"
                        v-if="dollarWallet"
                        @click="activateDollarToNaira"
                      >
                        <div
                          style="flex-basis:100%; background:#E6F4FF;padding:10px;border-radius: 5px;"
                        >
                          <h6
                            class="pt-2"
                            style="color:#4F4F4F; font-weight:600"
                          >
                            Withdraw dollars to reQuid naira wallet
                          </h6>
                          <div class="text-right">
                            <img
                              src="../../assets/images/union.png"
                              class="mr-3"
                              height="20"
                            />
                            <img
                              src="../../assets/images/vec.png"
                              height="20"
                            />
                          </div>
                        </div>
                        <!-- <div style="flex-basis:4%; ">
                                                                             <div style="border-right:1px solid #E0E0E0;">

                                                                             </div>
                                                                         </div> -->
                        <!-- <div style="flex-basis:48%; background:#E4FAE1;padding:10px;border-radius: 5px;" @click="activateDollarToBankAccount" class="point-cursor">
                                                                       
<h6 class="pt-2" style="color:#4F4F4F; font-weight:600">
   Withdraw dollars to bank account</h6>

<div class="text-right">
 <img src="../../assets/images/vec2.png" class="mr-3"  height="20">
 <img src="../../assets/images/vec.png"  height="20">

</div>
                                                                          
                                                                        </div> -->
                      </div>
                    </template>

                    <div
                      class="pin-div pr-4 pl-4 pb-4 mt-4"
                      v-if="editWithdrawal"
                      style="background:#F0F0F0; border-radius:20px"
                    >
                      <template v-if="userProfileInfo.has_pin">
                        <p class="text-center pt-4" style="color:#828282">
                          To confirm this transaction, enter your pin
                        </p>
                        <div class="eye-div widthraw-input">
                          <input
                            :type="hideInput"
                            v-model="userPin"
                            @keyup="checkIfUserTypesPin"
                            class="form-input text-center "
                            maxlength="4"
                            autocomplete="off"
                            readonly
                            onfocus="this.removeAttribute('readonly');"
                            style="border-bottom:1px solid #828282; box-shadow:0px"
                          />

                          <i
                            class="fa fa-eye-slash"
                            style="left:92%"
                            @click="showInputText"
                            v-if="showEye"
                          ></i>
                          <i
                            class="fa fa-eye"
                            style="left:92%"
                            @click="hideInputText"
                            v-if="hideEye"
                          ></i>
                        </div>
                      </template>
                    </div>
                    <hr v-if="editWithdrawal" />
                    <!-- <p class="text-center pt-4 pb-2">{{tran.expect_payment}}</p> -->
                    <p
                      class="text-center "
                      style="color:#828282;"
                      v-if="editWithdrawal"
                    >
                      Processing charges apply!
                    </p>
                  </template>
                  <div class="text-center" v-if="withdrawProcessing">
                    <img
                      src="../../assets/images/request.png"
                      class="mr-1 ml-1"
                      height="80"
                    />
                    <p style="color:#828282" class="mt-3">
                      Withdrawal request sent
                    </p>
                    <hr />
                    <div>
                      <div
                        style="background: #FFFAF0; text-align:center; padding:10px; border-radius:10px; display:flex; align-items:center; min-height:50px; justify-content:center;max-width: 329px; margin:0 auto"
                        class="mb-3"
                      >
                        <img
                          src="../../assets/images/info.png"
                          height="20"
                          class="mr-4"
                        />
                        <p>Expect funds within 1 - 2 Working days</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer text-center"
                  style="background: #fff"
                  v-if="
                    (nairaWallet &&
                      userProfileInfo.has_pin &&
                      !withdrawProcessing) ||
                      (editWithdrawal &&
                        userProfileInfo.has_pin &&
                        !withdrawProcessing)
                  "
                >
                  <div class="display-div">
                    <!-- <div data-dismiss="modal" aria-label="Close" v-if="transferDetail">
                                    <p style="color:#000">{{tran.cancel}}</p>
                                </div> -->
                    <div v-if="editWithdrawal">
                      <p style="color:#000" @click="editWithdrawalMethod">
                        {{ tran.edit_det }}
                      </p>
                    </div>
                    <div
                      v-if="!editWithdrawal && nairaWallet"
                      :class="{
                        'fade-elem':
                          withdrawErrorMsg || userWithdrawAmount < 100,
                      }"
                    >
                      <p style="color:#0094FF" @click="proceedToWithdraw">
                        {{ tran.continue }}
                        <i
                          class="fa fa-spin fa-spinner"
                          v-if="transferFundsLoader"
                        ></i>
                      </p>
                    </div>
                    <div v-if="editWithdrawal">
                      <p
                        style="color:#0094FF"
                        @click="withdrawFund"
                        v-if="nairaWallet"
                      >
                        Withdraw Funds
                        <i class="fa fa-spin fa-spinner" v-if="loader"></i>
                      </p>
                      <p
                        style="color:#0094FF"
                        @click="withdrawDollarFund"
                        v-if="dollarWallet"
                      >
                        Withdraw Funds
                        <i class="fa fa-spin fa-spinner" v-if="loader"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- RefferralModal -->

          <div
            class="modal fade"
            id="referralModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div
                  class="modal-header text-right"
                  style="border-bottom:1px solid #F2F2F2 !important;"
                >
                  <h4 class="modal-title head-font" id="exampleModalLongTitle">
                    {{ tran.referrals }}
                    <span style="color:#828282;; font-size:16px">
                      ({{ referraLength }}) -<b
                        ><span>&#8358;</span
                        >{{ formatAmount(referralTotalAmount) }}</b
                      >
                    </span>
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div v-if="referraLength < 1">
                    <p>Sorry you don't have any Referrals</p>
                  </div>

                  <div
                    v-for="ref in userProfileInfo.referral"
                    :key="ref.index"
                    style="display:flex; border-bottom:0.5px solid #F2F2F2; justify-content:space-between"
                    class="mb-3"
                  >
                    <div>
                      <p class="pb-1">
                        <b> {{ ref.first_name }} {{ ref.last_name }}</b>
                      </p>
                      <p style="color:#828282;">{{ ref.ref_code }}</p>
                    </div>

                    <div>
                      <p
                        v-if="ref.referral_amount !== null"
                        style="min-width:100px; padding:5px"
                        class="table-btn"
                      >
                        <span>&#8358;</span>
                        {{ formatAmount(ref.referral_amount) }}
                      </p>
                      <p
                        v-else
                        style="min-width:100px; padding:5px"
                        class="fade-ele-btn"
                      >
                        <span>&#8358;</span>{{ formatAmount(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--- Transfer Funds Modal -->

          <div
            class="modal fade"
            id="TransferFundsModal"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header text-right">
                  <h4 class="modal-title" id="exampleModalLongTitle">
                    {{ tran.transfer_funds }}
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="resetTransferState"
                  >
                    <span aria-hidden="true"> &times; </span>
                  </button>
                </div>

                <div class="modal-body">
                  <template v-if="transferDetail">
                    <div class="withdraw-bal">
                      <p>{{ tran.wallet_bal }} (N):</p>
                      <p class="text-center">
                        <b>{{ formatAmount(userProfileInfo.balance) }}</b>
                      </p>
                    </div>

                    <div style="min-height:100px" class="withdraw-input mt-4">
                      <div>
                        <p>{{ tran.amount_to_trans }}</p>
                        <money
                          v-model="userWithdrawAmount"
                          v-bind="money"
                        ></money>

                        <p
                          class="error-col pt-2"
                          style="    font-size: 12px;
    text-align: center;"
                          v-if="withdrawErrorMsg"
                        >
                          {{ tran.you_hv_insufficient_bal }}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p class="text-center pt-4">{{ tran.enter_recepient }}</p>
                      <div>
                        <input
                          type="email"
                          v-model="transferRecipientEmail"
                          class="form-input text-center "
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-if="editTransferDetailBtn">
                    <div>
                      <p>{{ tran.transfer }}</p>

                      <div
                        style="border:1px solid #E0E0E0; min-height:40px;display:flex;justify-content:center;align-items:center"
                      >
                        <p>
                          <span>&#8358;</span> {{ this.userWithdrawAmount }}
                        </p>
                      </div>
                      <p class="mt-3">{{ tran.to }}</p>

                      <div
                        style="border:1px solid #E0E0E0; min-height:40px;display:flex;justify-content:center;align-items:center"
                      >
                        <p>
                          {{ recepientTransferDetail.first_name }} &nbsp;
                          {{ recepientTransferDetail.last_name }}
                        </p>

                        <p>{{ recepientTransferDetail.message }}</p>
                      </div>
                    </div>
                    <p class="pt-2">
                      <b>Transfer Charge : </b> <span>&#8358;</span
                      >{{ recepientTransferDetail.charge }}
                    </p>

                    <div class="pin-div pr-4 pl-4 pb-4 mt-4">
                      <template v-if="userProfileInfo.has_pin">
                        <p class="text-center pt-4">
                          To confirm this transaction, enter your pin
                        </p>
                        <div class="eye-div widthraw-input">
                          <input
                            :type="hideInput"
                            v-model="userPin"
                            @keyup="checkIfUserTypesPin"
                            class="form-input text-center "
                            maxlength="4"
                            autocomplete="off"
                            readonly
                            onfocus="this.removeAttribute('readonly');"
                          />

                          <i
                            class="fa fa-eye-slash"
                            @click="showInputText"
                            v-if="showEye"
                          ></i>
                          <i
                            class="fa fa-eye"
                            @click="hideInputText"
                            v-if="hideEye"
                          ></i>
                        </div>
                      </template>

                      <template v-else>
                        <center>
                          <p class="pt-3">
                            you will need a PIN to confirm this transaction
                          </p>
                          <button
                            class="create-pin-btn mt-2"
                            @click="createPin"
                          >
                            Create a PIN
                          </button>
                        </center>
                      </template>
                    </div>
                  </template>
                </div>
                <div class="modal-footer text-center" style="background:#fff">
                  <div class="display-div">
                    <div
                      data-dismiss="modal"
                      aria-label="Close"
                      v-if="transferDetail"
                    >
                      <p style="color:#000">{{ tran.cancel }}</p>
                    </div>
                    <div v-if="editTransferDetailBtn">
                      <p style="color:#000" @click="editTransferDetail">
                        {{ tran.edit_det }}
                      </p>
                    </div>
                    <div
                      v-if="transferDetail"
                      :class="{
                        'fade-elem': withdrawErrorMsg || userWithdrawAmount < 1,
                      }"
                    >
                      <p style="color:#0094FF" @click="proceedToTransfer">
                        {{ tran.proceed }}
                        <i
                          class="fa fa-spin fa-spinner"
                          v-if="transferFundsLoader"
                        ></i>
                      </p>
                    </div>
                    <div v-if="editTransferDetailBtn">
                      <p style="color:#0094FF" @click="transferFunds">
                        Transfer fund
                        <i
                          class="fa fa-spin fa-spinner"
                          v-if="transferFundsLoader"
                        ></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import Wrapper from "../partials/Wrapper";
import trans from "../../../translations";
import http from "../../http/index";
import api from "../../apis/index";
import { mapState } from "vuex";
import { commonJs } from "./mixins/commonJs.js";
import $ from "jquery";
import { Money } from "v-money";
export default {
  mixins: [commonJs],

  data() {
    return {
      userWithdrawAmount: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
        masked: true,
      },
      activateNoty: false,
      tran: {},
      currentPage: 1,
      totalPages: 0,
      fundAmount: "",
      cardObject: null,
      minAmount: "",
      payWithCard: false,
      showModal: true,
      purchaseStatus: false,
      fadeFundWalletBtn: false,
      walletBalanceFromApi: true,
      userBalanceOncomponent: "",
      userBalanceFromApi: this.userBalance,
      sponsor: false,
      fundWalletFromWalletScreen: true,
      fundWallet: [
        {
          id: 1,
          card: "Fund with Card",
        },

        {
          id: 2,
          branch: "Fund with bank transfer",
        },
      ],

      fadeWithdrawBtn: true,
      withdrawErrorMsg: false,
      payType: null,
      checkingWalletStatus: false,
      confirmWalletData: {
        confirmBalance: true,
        confirmingPayment: false,
        fundAndPurchase: false,
        fundWalletUnsuccessful: false,
        walletFunded: false,
        userHasNotClickedConfirmPayment: true,
      },

      hideEye: false,
      showEye: true,
      hideInput: "password",
      userPin: "",
      editTransferDetailBtn: false,
      transferDetail: true,
      payWithCardInput: "",
      transferRecipientEmail: "",
      transferFundsLoader: false,
      recepientTransferDetail: {
        first_name: "",
        last_name: "",
        charge: "",
        message: "",
      },
      transferRecipientEmail: "",
      editWithdrawal: false,
      dollarWallet: false,
      nairaWallet: true,
      withdrawProcessing: false,
      dollarToBank: false,
      dollarToNairaWallet: true,
      dollarWithdrawalValue: 0,
    };
  },

  methods: {
    filterWalletData(walletData) {
      if (this.nairaWallet) {
        return walletData.filter((data) => {
          if (data.applicable_currency == "naira") {
            return data;
          }
        });
      }
      if (this.dollarWallet) {
        return walletData.filter((data) => {
          if (data.applicable_currency == "dollar") {
            return data;
          }
        });
      } else {
        return walletData;
      }
    },

    userWantsTosetPin() {
      this.$router.push({
        path: this.windowWidth < 993 ? "/profile/security" : "/profile",
        query: { openCreatePin: true },
      });
    },


    activateDollarToNaira() {
      this.editWithdrawal = true;
      this.dollarToNairaWallet = true;
      this.dollarToBank = false;
    },
    activateDollarToBankAccount() {
      this.dollarToBank = true;
      this.editWithdrawal = true;
      this.dollarToNairaWallet = false;
    },

    resetWallets() {
      this.editWithdrawal = false;
      this.withdrawProcessing = false;
    },
    activateDollarTrans() {
      this.dollarWallet = true;
      this.nairaWallet = false;
    },
    activateNairaTrans() {
      this.dollarWallet = false;
      this.nairaWallet = true;
    },

    resetTransferState() {
      this.transferDetail = true;
      this.editTransferDetailBtn = false;
    },

    proceedToWithdraw() {
      this.editWithdrawal = true;
    },
    editWithdrawalMethod() {
      this.editWithdrawal = false;
    },

    editTransferDetail() {
      this.transferDetail = true;
      this.editTransferDetailBtn = false;
    },
    proceedToTransfer() {
      this.transferFundsLoader = true;

      http
        .get(api.transferFunds, {
          params: {
            amount: this.userWithdrawAmount.toString().replace(/,/g, ""),
            email: this.transferRecipientEmail,
          },
        })
        .then((response) => {
          this.$helpers.log(response);
          this.transferFundsLoader = false;
          this.transferDetail = false;
          this.editTransferDetailBtn = true;
          this.recepientTransferDetail = response.data.data;
          this.recepientTransferDetail.message = response.data.message;
        })

        .catch((error) => {
          this.transferFundsLoader = false;

          this.$helpers.log(error.response);

          this.$toast.warn({
            timeOut: 5000,
            icon: require("../../assets/images/cautionicon.png"),
            title: this.tran.sorry,
            message: error.response.data.message,
            position: "top center",
          });
        });
    },
    transferFunds() {
      let vm = this;
      this.transferFundsLoader = true;

      let apiData = {
        amount: this.userWithdrawAmount.toString().replace(/,/g, ""),
        email: this.transferRecipientEmail,

        pin: this.userPin,
      };
      http
        .post(api.transferFunds, apiData)
        .then((response) => {
          this.$helpers.log(response);
          this.transferFundsLoader = false;

          $("#TransferFundsModal").modal("hide");
          $(".modal-backdrop").remove();

          this.$store.dispatch("auth/getUserProfile");

          this.$toast.success({
            title: "Funds transfer successful",
            icon: require("../../assets/images/successicon.png"),
            timeOut: 8000,
            message: `The Recipient’s wallet has been credited successfully.`,
            position: "top center",
          });
          this.transferDetail = true;
          this.editTransferDetailBtn = false;
          this.transferRecipientEmail = "";

          this.userPin = "";
        })

        .catch((error) => {
          vm.transferFundsLoader = false;
          this.$helpers.log(error.response);

          this.$toast.warn({
            timeOut: 5000,
            icon: require("../../assets/images/cautionicon.png"),
            title: this.tran.sorry,
            message: error.response.data.message,
            position: "top center",
          });
        });
    },

    createPin() {
      $(".modal-backdrop").removeClass("modal-backdrop");
      this.$router.push("/create/pin");
    },
    emptyWithdrawData() {
      this.userPin = "";
      this.userWithdrawAmount = 0;
    },

    checkForSponsor(event) {
      if (event.target.checked) {
        this.sponsor = true;
      } else {
        this.sponsor = false;
      }
    },

    payWithPaystack(paymentDetail) {
      // let paymentDetail = this.cardObject;
      let vm = this;

      var handler = PaystackPop.setup({
        key: paymentDetail.provider_key,
        email: paymentDetail.email,

        amount: paymentDetail.amount_kobo,
        currency: "NGN",
        ref: paymentDetail.pay_ref,

        callback: function(response) {
          vm.$store.dispatch("market/getAccountOverview");
          // let verify_pay = {
          // reference: response.reference
          //}

          //vm.verifyCardPayment(verify_pay)
          vm.getAccountBalance();

          try {
            gtag("event", "conversion", {
              send_to: "AW-703278616/P1_5CLGz-bMBEJjcrM8C",
            });
          } catch {}
        },
        onClose: function() {
          // vm.$router.push('/admin/transaction');
        },
      });
      handler.openIframe();
    },

    verifyCardPayment(payload) {
      let vm = this;
      http
        .get(api.verifyPaymentByCard + "?reference=" + `${payload.reference}`)
        .then((response) => {
          this.$helpers.log(response, "card verified");
          vm.getAccountBalance();
        })
        .catch((error) => {
          this.$helpers.log(error.response, "card unverified flag");
          this.$store.commit("domElements/openNotificationMessage");

          this.$store.commit("domElements/saveNotificationMessage");
        });
    },

    getAccountBalance() {
      http
        .get(api.getUserProfile)
        .then((response) => {
          this.$helpers.log(response, "new account balance");
          this.userBalanceOncomponent = response.data.data.balance;
        })
        .catch((error) => {
          this.$helpers.log(error.response);
          this.$store.commit("domElements/openNotificationMessage");

          this.$store.commit("domElements/saveNotificationMessage");
        });
    },

    addToUsers() {
      this.users.unshift(this.userData);
    },

    userWantsToPayWithCard() {
      this.payWithCard = true;
      $("#cardPay").focus();
      $(".modal").animate({ scrollTop: $(".modal").height() }, "slow");
    },
    closePayWithCardBox() {
      this.payWithCard = false;
      this.fadeFundWalletBtn = true;
      this.payWithCardInput = "";
    },

    showInputText() {
      this.hideInput = "text";
      this.showEye = false;
      this.hideEye = true;
    },

    hideInputText() {
      this.hideInput = "password";
      this.showEye = true;
      this.hideEye = false;
    },

    sendToMarketPlace() {
      this.$router.push("/market");
    },
    informUser(message) {
      $(".modal-backdrop").removeClass("modal-backdrop");
      this.showModal = false;
      this.$toast.success({
        title: this.tran.success,
        timeOut: 5000,
        icon: require("../../assets/images/successicon.png"),
        message: message,
        position: "top center",
      });
      this.getAccountBalance();
      setTimeout(() => {
        this.showModal = true;
      }, 2000);
    },

    fundWalletNow() {
      let vm = this;
      let amountData = {
        amount: this.minAmount.toString().replace(/,/g, ""),
        sponsor: this.sponsorStatus,
      };

      if (amountData.amount > 1) {
        this.$store.dispatch("market/fundWalletOffline", amountData);
      }

      // let startInterval = setInterval(() => {

      //     http.get(api.getUserBalance)
      //         .then(response => {
      //             vm.$helpers.log(response, "new account balance");

      //             if (vm.cartItemLength == null && response.data.data.balance !== vm.userProfileInfo.balance) {

      //                 vm.informUser(vm.tran.your_wallet_success);
      //                 clearInterval(startInterval)
      //                 vm.$store.dispatch('auth/getUserProfile')
      //                 vm.$store.dispatch('market/getCartStatus')
      //             } else if (vm.cartItemLength !== null) {

      //                 if (response.data.data.balance !== vm.userProfileInfo.balance && response.data.data.cart_item_count == 0) {
      //                     vm.informUser(vm.tran.your_wallet_success_items_bought);

      //                     vm.$router.push('/portfolio')
      //                     clearInterval(startInterval)
      //                     vm.$store.dispatch('auth/getUserProfile')
      //                     vm.$store.dispatch('market/getCartStatus')

      //                 } else if (response.data.data.balance !== vm.userProfileInfo.balance &&                                      response.data.data.cart_item_count > 0) {

      //                     vm.informUser(vm.tran.your_wallet_success);
      //                     clearInterval(startInterval)
      //                     vm.$store.dispatch('auth/getUserProfile')
      //                     vm.$store.dispatch('market/getCartStatus')
      //                 }
      //             }

      //         })
      //         .catch(error => {
      //             vm.$helpers.log(error.response);
      //             vm.$store.commit("domElements/openNotificationMessage")

      //             vm.$store.commit("domElements/saveNotificationMessage")
      //         });

      // }, 9000)
    },

    checkIfWalletHasBeenFunded() {
      let oldBalance = this.userProfileInfo.balance;

      let cartLength = this.cartItemLength;

      if (cartLength == undefined) {
        cartLength = 0;
      }
      this.checkingWalletStatus = true;
      this.confirmWalletData.confirmingPayment = true;
      let vm = this;
      // let walletLength = thisWall

      vm.$helpers.log(vm.cartItemLength, "cartItemLengjt");
      http
        .get(
          "api/v1/account/payment/confirmation" +
            "?cart_item_count=" +
            cartLength +
            "&" +
            "balance=" +
            oldBalance
        )
        .then((response) => {
          vm.$helpers.log(response, "new account balance");
          vm.checkingWalletStatus = false;

          //if user pays successfully

          if (response.data.status) {
            vm.$helpers.log(response, "response in time");

            if (
              response.data.data.payment &&
              response.data.data.cart == false
            ) {
              vm.$store.dispatch("market/getCartStatus");

              vm.confirmWalletData = {
                fundAndPurchase: false,
                fundWalletUnsuccessful: false,
                walletFunded: true,
                confirmingPayment: false,
                userHasNotClickedConfirmPayment: false,
                confirmBalance: false,
              };

              try {
                gtag("event", "conversion", {
                  send_to: "AW-703278616/P1_5CLGz-bMBEJjcrM8C",
                });
              } catch {}
            }

            //if user pays successfully and buys items in cart
            else if (response.data.data.payment && response.data.data.cart) {
              this.$store.commit("market/emptyCartItems");
              vm.confirmWalletData = {
                confirmBalance: false,
                confirmingPayment: false,
                fundAndPurchase: true,
                fundWalletUnsuccessful: false,
                walletFunded: false,
                userHasNotClickedConfirmPayment: false,
              };
              setTimeout(() => {
                vm.showModal = true;
                $(".modal-backdrop").removeClass("modal-backdrop");
                vm.$router.push("/portfolio");
              }, 8000);

              try {
                fbq("track", "Purchase", { value: payload, currency: "NGN" });
                gtag("event", "conversion", {
                  send_to: "AW-703278616/P1_5CLGz-bMBEJjcrM8C",
                });
              } catch {}
            }
          }

          vm.getAccountBalance();
        })

        .catch((error) => {
          vm.$helpers.log(error.response);
          vm.confirmWalletData.confirmingPayment = false;
          vm.$store.commit("domElements/openNotificationMessage");
          vm.checkingWalletStatus = false;
          vm.$store.commit(
            "domElements/saveNotificationMessage",
            error.response.data.message
          );
          vm.confirmWalletData = {
            confirmBalance: true,
            fundAndPurchase: false,
            fundWalletUnsuccessful: true,
            walletFunded: false,
            confirmingPayment: false,
            userHasNotClickedConfirmPayment: false,
          };
        });
    },

    getFundValue(e) {
      if (this.payWithCardInput == "") {
        this.payWithCardInput = "";
        return;
      }

      let fundValue = e.target.value;
      e.target.value = parseInt(
        fundValue.replace(/\D/g, ""),
        10
      ).toLocaleString();

      this.fundAmount = e.target.value;
      this.minAmount = e.target.value;
      let inputValue = this.fundAmount.toString().replace(/,/g, "");

      if (
        isNaN(this.minAmount.toString().replace(/,/g, "")) ||
        isNaN(this.payWithCardInput.toString().replace(/,/g, ""))
      ) {
        this.minAmount = "";
        this.payWithCardInput = "";
      }
      this.$helpers.log(inputValue, "value of input");

      if (inputValue >= 1) {
        this.fadeFundWalletBtn = false;
      } else {
        this.fadeFundWalletBtn = true;
      }
    },

    getWithdrawFundValue(e) {
      let inputValue = e.target.value.toString().replace(/,/g, "");

      if (parseFloat(inputValue) > parseFloat(this.userProfileInfo.balance)) {
        this.fadeWithdrawBtn = true;
        this.withdrawErrorMsg = true;
      } else {
        this.withdrawErrorMsg = false;
      }
      if (
        (parseFloat(inputValue) < parseFloat(this.userProfileInfo.balance) &&
          inputValue >= 1 &&
          this.userPin.length == 4) ||
        (parseFloat(inputValue) == parseFloat(this.userProfileInfo.balance) &&
          parseFloat(inputValue) >= 1 &&
          this.userPin.length == 4)
      ) {
        this.fadeWithdrawBtn = false;
        this.withdrawErrorMsg = false;
      } else {
        this.fadeWithdrawBtn = true;
      }
      this.fundAmount = inputValue;
    },

    checkIfUserTypesPin() {
      if (this.userPin.length == 4 && this.fundAmount !== "") {
        this.fadeWithdrawBtn = false;
        this.withdrawErrorMsg = false;
      } else {
        this.fadeWithdrawBtn = true;
      }
    },

    showFundModalOption() {
      this.fundWalletWithMinimumAmount();

      try {
        ga("send", "event", "business goals", "fund wallet", this.minAmount);
      } catch {}
    },

    fundWalletOnline() {
      this.$store.commit("market/startPreloader");
      // this.$store.commit("market/startLoader");
      this.payWithCardInput = this.minAmount;
      let amountData = {
        amount: this.minAmount.toString().replace(/,/g, ""),
        sponsor: this.sponsorStatus,
      };
      let vm = this;
      //   this.$helpers.log(amountData, 'getAmount')
      http
        .post(api.payOnline, amountData)

        .then((response) => {
          vm.$helpers.log(response, "gone");
          vm.payWithPaystack(response.data.data);
          vm.$store.commit("market/stopLoader");
          vm.$store.commit("market/stopPreloader");

          try {
            vm.closePayWithCardBox();
          } catch {}
        })

        .catch((error) => {
          try {
            this.$store.commit("market/stopPreloader");
            this.$helpers.log(error.response.data.message);
            this.$store.commit("market/stopLoader");
            this.$store.commit("domElements/openNotificationMessage");

            this.$store.commit(
              "domElements/saveNotificationMessage",
              error.response.data.message
            );
            this.$helpers.log(error.response);
          } catch {}
        });
    },

    fundWalletOffline() {
     this.transferFundsLoader =true
      let vm = this;
      //   this.$helpers.log(amountData, 'getAmount')
      http
        .get(api.payOffline)

        .then((response) => {
          vm.$helpers.log(response, "gone");
          vm.$store.commit("auth/saveVirtualAccount", response.data.data);
          vm.transferFundsLoader =false
vm.dispatch('auth/getUserProfile')
          try {
           
          } catch {}
        })

        .catch((error) => {
          try {
            vm.transferFundsLoader =false
            this.$helpers.log(error.response);
          } catch {}
        });
    },
    withdrawFund() {
      let withdrawData = {
        amount: this.userWithdrawAmount.replace(/,/g, ""),
        // sponsor: this.sponsorStatus,
        pin: this.userPin,
      };

      let vm = this;
      this.$store.commit("market/startLoader");

      //   this.$helpers.log(amountData, 'getAmount')
      http
        .post(api.withdrawFund, withdrawData)

        .then((response) => {
          this.$store.dispatch("auth/getUserProfile");
          vm.getAccountBalance();
          this.withdrawProcessing = true;
          this.$helpers.log(response);
          this.$store.commit("market/stopLoader");
        })

        .catch((error) => {
          if (error.response) {
            if (error.response.data.message !== "Unauthenticated.") {
              this.$store.commit("domElements/openNotificationMessage");

              this.$store.commit(
                "domElements/saveNotificationMessage",
                error.response.data.message
              );
              this.$helpers.log(error.response);
            }
          }
          this.$store.commit("market/stopLoader");
        });
    },
    withdrawDollarFund() {
      let withdrawData = {
        amount_usd: this.dollarWithdrawalValue.replace(",", ""),
        // sponsor: this.sponsorStatus,
        pin: this.userPin,
      };

      let vm = this;
      this.$store.commit("market/startLoader");

      //   this.$helpers.log(amountData, 'getAmount')
      http
        .post(api.withdrawDollarFund, withdrawData)

        .then((response) => {
          this.$helpers.log(response);
          this.$store.dispatch("auth/getUserProfile");
          vm.getAccountBalance();
          this.withdrawProcessing = true;

          this.$store.commit("market/stopLoader");
        })

        .catch((error) => {
          if (error.response) {
            if (error.response.data.message !== "Unauthenticated.") {
              this.$store.commit("domElements/openNotificationMessage");

              this.$store.commit(
                "domElements/saveNotificationMessage",
                error.response.data.message
              );
              this.$helpers.log(error.response);
            }
          }
          this.$store.commit("market/stopLoader");
        });
    },
    purchaseProduct() {
      this.purchaseStatus = true;
      this.$store.dispatch("market/purchaseProduct");
    },

    // filterWalletData(tableData) {
    //  return tableData.filter(prod => {
    //   if (prod.status.toLowerCase().includes(this.searchString.toLowerCase()) || prod.amount.includes(this.searchString.toLowerCase())) {
    //   return true;
    //     } else if (this.searchString == '') {

    //     return true;
    //  }
    //     });
    //   },

    fundWalletWithMinimumAmount(vestLockAmount) {
      if (
        this.userProfileInfo.balance < this.cartTotalAmount &&
        this.cartItemLength > 0
      ) {
        this.minAmount = (this.cartTotalAmount - this.userBal).toFixed(2);
      } else {
        this.minAmount = 5000;
      }
      if (vestLockAmount) {
        this.minAmount = (this.$route.query.fundAmount - this.userBal).toFixed(
          2
        );
      }
    },

    sendToProfilePage() {
      this.$router.push("/profile");
      this.$toast.warn({
        title: this.tran.bank_details,
        timeOut: 6000,
        message: this.tran.payout_details_before_withdr,
        position: "top right",
      });
    },
  },
  computed: {
    ...mapState({
      offlineDetailModal: (state) => state.market.offlineDetailModal,
      fundOptionModal: (state) => state.market.fundOptionModal,
      offlinePaymentDetail: (state) => state.market.offlinePaymentDetail,
      accountOverview: (state) => state.market.accountOverview,
      offlineMinAmount: (state) => state.market.offlineMinAmount,
      accountOverviewBonus: (state) => state.market.accountOverviewBonus,
      accountOverviewCredits: (state) => state.market.accountOverviewCredits,
      accountOverviewDebits: (state) => state.market.accountOverviewDebits,
      transferNotification: (state) => state.auth.transferNotification,
      notificationMessageFromAuth: (state) =>
        state.auth.notificationMessageFromAuth,
      referraLength: (state) => state.auth.referraLength,
      referralTotalAmount: (state) => state.auth.referralTotalAmount,
      portfolioSummary: (state) => state.market.portfolioSummary,
      walletTransactions: (state) => state.market.walletTransactions,
      walletData: (state) => state.market.walletData,
      userBal: (state) => state.market.userBalance,
      userBankName: (state) => state.auth.userProfileInfo.bank_account_name,
      portPreLoader: (state) => state.market.portPreLoader,
    }),

    sponsorStatus() {
      return this.sponsor;
    },

    userAccountNumberSpaced() {
      return this.offlinePaymentDetail.account_number
        .toString()
        .replace(/\B(?=(\d{4})+(?!\d))/g, " ");
    },
    returnShowModal() {
      return this.showModal;
    },
    returnFundBtnStatus() {
      return this.fadeFundWalletBtn;
    },
    returnDollarEquiv() {
      return (
        this.dollarWithdrawalValue
          .toString()
          .toString()
          .replace(/,/g, "") * this.walletData.usd_exchange_rate_sell
      );
    },

    returnMinAmount() {
      if (this.minAmount.toString().replace(/,/g, "") % 1 !== 0) {
        return this.minAmount.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      } else {
        return this.minAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    returnMinAmountUserIsPaying() {
      if (this.minAmount == "") {
        return "";
      }
      if (this.minAmount.toString().replace(/,/g, "") % 1 !== 0) {
        return (
          parseFloat(this.minAmount.toString().replace(/,/g, "")) +
          parseFloat(50)
        )
          .toString()
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      } else {
        return (
          (
            parseFloat(this.minAmount.toString().replace(/,/g, "")) +
            parseFloat(50)
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"
        );
      }
    },
    returnCheckingWalletStatus() {
      return this.checkingWalletStatus;
    },

    //       returnMinAmountUserIsPaying() {

    //         if (this.minAmount == '') {

    //             return ''

    //         }
    //  let userAmount = parseFloat(this.minAmount) + parseFloat(50)

    //         return userAmount.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')
    //     },

    returnComponentBalance() {
      return this.userBalanceOncomponent;
    },

    filterWallet() {
      return this.walletTransactions.filter((prod) => {
        return (
          prod.tranx_type
            .toLowerCase()
            .match(this.searchString.toLowerCase()) ||
          prod.amount
            .toString()
            .replace(/,/g, "")
            .match(this.searchString.toLowerCase())
        );
      });
    },
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper,
    Money,
  },

  deactivated() {
    this.$destroy();
  },
  mounted() {
    if (this.$route.query.activateFundButton) {
      this.activateNoty = true;
    }

    $(".v-money").keyup((e) => {
      this.getWithdrawFundValue(e);
    });

    if (this.transferNotification) {
      this.$toast.success({
        title: this.tran.success,
        icon: require("../../assets/images/successicon.png"),
        timeOut: 5000,
        message: this.notificationMessageFromAuth,
        position: "top center",
      });
      this.$store.commit("auth/closetransferNotification");
    }

    if (this.$route.query.openFundWallet == true) {
      this.$store.commit("market/showFundOptionModal");
      $("#fundWallet").modal("show");
      this.fundWalletWithMinimumAmount(true);
      this.fundWalletFromWalletScreen = false;
      $("#fundInput").focus();
    }

    let heading = {
      main: this.tran.wallet,
      sub: this.tran.credit_debit,
    };

    this.$store.commit("market/savePageHeading", heading);
    this.tran = trans;
    this.$store.dispatch("market/getWalletData");

    //save the current route if user is not authenticated, redirect the user after authenticating
    this.$store.commit("auth/saveCurrentRoute", this.$route.path);
  },
  beforeCreate() {
    // this.$store.dispatch('market/getAllProducts');
    this.$store.commit("market/checkSearchObject", "wallet");
    //this.$store.dispatch('market/getAccountOverview')
  },

  beforeDestroy() {
    this.$store.commit("market/startIsFetching");
    this.$store.commit("market/startPreloader");
  },
};
</script>

<style lang="scss" scoped>
.pay-with-card-link {
  position: relative;
  background: #41ccaf;
  border-top: 1px solid #40cbb1;
  transition: height 0.8s ease;
  overflow: hidden;
  padding: 0rem;
  border-radius: 10px;
}

.pay-with-card {
  background: #fff !important;
  border-radius: 10px;
  height: 175px;
  padding: 1rem 2em !important;
  min-height: 49px !important;
  min-width: 100%;
  @media (max-width: 767px) {
    padding: 1rem 0.6em !important;
  }
}

.continue-active {
  color: #0094ff !important;
}

.continue-inactive {
  color: #bdbdbd !important;
}
.card-title-amount {
  font-size: 18px !important;
}
.card-title {
  font-weight: 300 !important;
}
@media (max-width: 356px) {
  .card-title-amount {
    font-size: 14px !important;
  }
}
.modal-body {
  padding: 1rem 1em !important;
}
</style>
